import "./FooterItem.css";
import { Link } from "react-router-dom";

function FooterItem() {
  return (
    <footer className="footer">
      <div>
        <p className="footerText">Copyright © FIRSTPEEL INC. 2025</p>
        <p className="footerText">contact@firstpeel.com</p>
      </div>
      <div>
        <Link to="/" className="footerText">
          Home
        </Link>
        <Link to="/newterms" className="footerText">
          Terms and Conditions
        </Link>
        <Link to="/newprivacy" className="footerText">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}

export default FooterItem;
