import "./HomeScreen.css";
import logo from "../assets/Logo.png";
import GetWindowWidth from "../assets/GetWindowWidth";
import MP4Animation from "../assets/FP.mp4";

import { useState, useRef } from "react";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { pauseOutline, play } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import FooterItem from "../FooterItem";

function HomeScreen() {
  // Response messages for the waitlist submission
  const numberAdded = "You were successfully added to the waitlist!";
  const alreadyAdded = "You are already on the waitlist!";
  const invalidNumber = "Invalid phone number. Please try again.";
  const submissionError =
    "Error submitting phone number. If this continues, email contact@firstpeel.com";
  const alreadyOptedOut =
    "You have previously opted out. You will not receive a message from us.";

  const viewWidth = GetWindowWidth();

  // State management
  const [phoneTextValue, setPhoneTextValue] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [message, setMessage] = useState("");
  const [videoError, setVideoError] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const videoRef = useRef(null);

  async function submitPhoneNumber() {
    setMessage("");
    // Remove all non-numeric characters from the phone number
    const phoneNumber = phoneTextValue.replace(/\D/g, "");
    const response = await sendUserInfo(phoneNumber);
    setPhoneTextValue("");
    setIsPhoneValid(false);

    if (response.added) {
      setMessage(numberAdded);
    } else if (!response.isPhoneNumberValid) {
      setMessage(invalidNumber);
    } else if (response.isOptedOut) {
      setMessage(alreadyOptedOut);
    } else if (response.alreadyExists) {
      setMessage(alreadyAdded);
    } else {
      setMessage(
        "Error submitting phone number. If this continues, email contact@firstpeel.com"
      );
    }
  }

  // Function for sending user info to the server
  async function sendUserInfo(phoneNumber) {
    const endpoint =
      "https://8mxxtmyzl6.execute-api.us-west-1.amazonaws.com/default/add";
    const body = {
      phoneNumber: phoneNumber,
    };

    try {
      // Make a post request to AWS API Gateway
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      setMessage(submissionError);
    }
  }

  // Function for handling phone number input changes
  function handlePhoneInputChange(event) {
    setPhoneTextValue(event.target.value);
    setMessage("");
    if (event.target.value.length === 14) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  }

  function handleError() {
    setVideoError(true);
  }

  function handleCanPlay() {
    setVideoReady(true);
  }

  function pauseVideo() {
    if (videoPaused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setVideoPaused((current) => !current);
  }

  const clickable = {
    color: "black",
    fontFamily: "SourceSans3",
    fontWeight: "500",
    fontSize: "1.5rem",
    border: "2px solid black",
    borderLeft: "none",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "1rem",
    backgroundColor: "transparent",
    width: "8rem",
    cursor: "pointer",
  };

  const notClickable = {
    color: "rgba(0,0,0,0.2)",
    fontFamily: "SourceSans3",
    fontWeight: "500",
    fontSize: "1.5rem",
    border: "2px solid black",
    borderLeft: "none",
    borderBottomRightRadius: "1rem",
    borderTopRightRadius: "1rem",
    backgroundColor: "transparent",
    width: "8rem",
  };

  const normalAnimation = {
    width: "80%",
    height: "48rem",
    alignSelf: "center",
    justifySelf: "center",
  };

  const mobileAnimation = {
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    alignItems: "center",
    justifyContent: "center",
    height: viewWidth * 0.6,
  };

  return (
    <div className="container">
      <Helmet>
        <title>Firstpeel Home Screen</title>
      </Helmet>
      <div className="bannerContainer">
        <p className="bannerText">
          We updated our Privacy Policy to clarify how we handle your info.{" "}
          <Link
            to="/newprivacy"
            style={{
              textDecoration: "underline",
              color: "inherit",
            }}
            tabIndex={"0"}
          >
            View Here
          </Link>
        </p>
      </div>
      <div className="logoContainer">
        <img src={logo} alt="logo" className="logoImage" />
        <p className="logoText">Firstpeel</p>
      </div>
      <div className="App">
        <div className="leftScreen">
          <div className="headerContainer">
            <p className="header1Text">
              Online Dating Just Got
              <br />
              <span className="header1TextEmphasis">So Much Better.</span>
            </p>
            <p className="header2Text">
              Get a text when we're available on the App Store.
            </p>
            <p className="phoneNumberInputLabel">Phone Number</p>
            <div className="waitlistPhoneContainer">
              <InputMask
                mask="(999) 999-9999"
                maskChar={null}
                value={phoneTextValue}
                onChange={handlePhoneInputChange}
                aria-label="Phone Number Input"
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="tel"
                    className="phoneNumberInput"
                    style={{
                      backgroundColor: inputFocused
                        ? "rgba(255,255,255,0.4)"
                        : "transparent",
                      border: inputFocused && "3px solid black",
                    }}
                    placeholder="(999) 999-9999"
                  ></input>
                )}
              </InputMask>
              <button
                style={isPhoneValid ? clickable : notClickable}
                disabled={!isPhoneValid}
                onClick={submitPhoneNumber}
                tabIndex={"0"}
              >
                Join
                <br />
                Waitlist
              </button>
            </div>
            <p
              className="fade-in-message"
              style={{ visibility: inputFocused ? "visible" : "hidden" }}
            >
              Make sure to double-check your number before submitting.
            </p>
            <p className="responseText" role="alert">
              {message}
            </p>
            <p className="messageText">
              If you choose to enter a phone number, we will send you a one-time
              text message (nothing more) when the app is available for download
              on the IOS and Google Play store.{" "}
              <span style={{ fontWeight: 500 }}>
                The first 1,000 eligible individuals to register their phone
                number to get a text message will get free lifetime access to
                all premium features of the Firstpeel app (see{" "}
                <Link
                  to="/newterms"
                  style={{ textDecoration: "underline", color: "inherit" }}
                  tabIndex={"0"}
                >
                  Terms and Conditions
                </Link>{" "}
                for details).
              </span>{" "}
              Message and data rates may apply. By using our website, clicking
              "Join Waitlist" and/or interacting with our toll-free number (+1
              888-698-4880), you agree to our{" "}
              <Link
                to="/newterms"
                style={{ textDecoration: "underline", color: "inherit" }}
                tabIndex={"0"}
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                to="/newprivacy"
                style={{ textDecoration: "underline", color: "inherit" }}
                tabIndex={"0"}
              >
                Privacy Policy
              </Link>{" "}
              . Opt out any time by texting STOP to +1 888-698-4880 if you no
              longer want to be notified. Note: You cannot be added back to the
              waitlist if you opt out. Any questions? Contact us at
              contact@firstpeel.com.
            </p>
          </div>
        </div>
        <div className="rightScreen">
          {!videoError && (
            <div style={viewWidth < 600 ? mobileAnimation : normalAnimation}>
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: "auto",
                  height: "100%",
                  opacity: videoReady ? 1 : 0,
                  transition: "opacity .5s",
                  marginLeft: "1.6rem",
                }}
                aria-label="A phone showing screens with the phrases 'Fake Profiles,' 'Limited Swipes,' 'High Pricing, and 'Basic Features' being swiped left."
                src={MP4Animation}
                onError={handleError}
                onCanPlay={handleCanPlay}
                ref={videoRef}
              />
              <div className="playbackButtonContainer">
                <button className="videoPlayback" onClick={pauseVideo}>
                  {videoPaused ? (
                    <IonIcon icon={play} className="playbackIcon" />
                  ) : (
                    <IonIcon icon={pauseOutline} className="playbackIcon" />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <FooterItem />
    </div>
  );
}

export default HomeScreen;
