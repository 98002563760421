import "./PrivacyScreen.css";
import { Link } from "react-router-dom";
import { chevronBackCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Helmet } from "react-helmet";
import FooterItem from "../FooterItem";
import logo from "../assets/Logo.png";

function NewPrivacyScreen() {
  return (
    <div className="privacyContainer">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="privacyHeaderContainer">
        <Link to="/" className="backButtonContainer">
          <IonIcon icon={chevronBackCircle} className="backButton" />
        </Link>
        <div className="privacyLogoContainer">
          <img src={logo} alt="logo" className="privacyLogoImage" />
          <p className="privacyLogoText">Firstpeel</p>
        </div>
      </div>
      <div className="privacyContent">
        <h1 className="center title">Privacy Policy</h1>

        <main>
          <h3>Effective January 1, 2025</h3>

          <section>
            <p>
              Welcome! This privacy policy for FIRSTPEEL INC. (“
              <strong>we,</strong>” “<strong>us,</strong>” or “
              <strong>our</strong>
              ”), describes how and why we might collect, store, use, and
              or/share (“
              <strong>process</strong>”) your information when you use our
              services (“
              <strong>Services</strong>”), such as when you:
            </p>
            <ul>
              <li>
                Visit our website at{" "}
                <Link to="/">https://www.firstpeel.com</Link>.
              </li>
              <li>
                Use any features available on our website, such as the
                functionality where users can register their phone numbers to
                receive an SMS notification regarding the launch of our mobile
                dating app.
              </li>
              <li>
                Interact with our toll-free number (+1 888-698-4880) for
                services such as managing opt-out requests.
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy
              policy will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at information@firstpeel.com.
            </p>
          </section>

          <section>
            <h1>Summary Of Key Points</h1>
            <p>
              <strong>
                This summary provides key points from our privacy policy, but
                you can find out more details about any of these topics below.
              </strong>
            </p>
            <p>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with us and the
              Services, and the choices you make. Learn more about{" "}
              <a href="#personalInfo">
                personal information you disclose to us.
              </a>
            </p>
            <p>
              <strong>
                Do we process any sensitive personal information?{" "}
              </strong>
              We do not process sensitive personal information.
            </p>
            <p>
              <strong>Do we receive any information from third parties?</strong>{" "}
              We do not receive any information from third parties.
            </p>
            <p>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for promotional purposes, for security and
              fraud prevention, to comply with law, and to manage legal matters.
              We may also process your information for other purposes with your
              consent. We process your information only when we have a valid
              legal reason to do so. Learn more about{" "}
              <a href="#processInfo">how we process your information.</a>
            </p>
            <p>
              <strong>
                In what situations and with which parties do we share personal
                information?{" "}
              </strong>
              We may share information in specific situations and with specific
              third parties. Learn more about{" "}
              <a href="#shareInfo">
                when and with whom we share your personal information.
              </a>
            </p>
            <p>
              <strong>How do we keep your information safe?</strong> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about{" "}
              <a href="#safeInfo">how we keep your information safe.</a>
            </p>
            <p>
              <strong>What are your rights?</strong> You have certain rights
              regarding your personal information. Learn more about{" "}
              <a href="#privacyRights">your privacy rights.</a>
            </p>
            <p>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by contacting us at
              information@firstpeel.com. We will respond to your request
              promptly and appropriately.
            </p>
            <p>
              Want to learn more about what we do with any information we
              collect? Review the privacy policy in full.
            </p>
          </section>

          <section>
            <h1 id="personalInfo">1. What Information Do We Collect?</h1>
            <p>
              <strong>Personal information you disclose to us</strong>
            </p>

            <p>
              <strong>In Short:</strong> We collect personal information that
              you provide to us.
            </p>

            <p>
              We collect personal information, in various ways, that you
              voluntarily provide to us when you visit or use any part of our
              Services.
            </p>

            <p>
              <strong>Personal Information Provided by You:</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, and the choices you
              make. The personal information we collect may include the
              following:
            </p>
            <ul>
              <li>Phone numbers.</li>
              <li>
                Timestamps to record the time when a user submitted their phone
                number.
              </li>
              <li>
                The content of SMS messages sent to our toll-free number (+1
                888-698-4880).
              </li>
            </ul>
            <p>
              <strong>Sensitive information:</strong> We do not process
              sensitive information.
            </p>

            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>

            <p>
              <strong>Personal information automatically collected:</strong>
            </p>

            <p>
              <strong>In Short:</strong> Some information - such as your
              Internet Protocol (IP) address and/or browser and device
              characteristics - is collected automatically when you interact
              with our Services.
            </p>

            <p>
              We automatically collect certain information through sources like
              our website’s access logs when you visit, use, or navigate our
              Services. This may include technical data (e.g., date and time of
              access), device and browser information (e.g., user agent info),
              and network and connection data (e.g., IP address). This
              information does not reveal your specific identity (like your name
              or contact information), but is primarily needed to maintain the
              security and operation of our Services, and for our internal
              analytics and reporting purposes.
            </p>
          </section>

          <section>
            <h1 id="processInfo">2. How Do We Process Your Information?</h1>

            <p>
              <strong>In Short:</strong> We process your information to provide,
              improve, and administer our Services, communicate with you, for
              promotional purposes, for security and fraud prevention, to comply
              with law, and to manage legal matters. We may also process your
              information for other purposes with your consent.
            </p>

            <p>
              <strong>
                We process your personal information for a variety for reasons,
                depending on how you interact with our Services, including:
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  To deliver and facilitate delivery of services to the user.
                </strong>{" "}
                We may process your information to provide you with the
                requested service.
              </li>
              <li>
                <strong>
                  To respond to user inquiries/offer support to users.
                </strong>{" "}
                We may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service.
              </li>
              <li>
                <strong>
                  To evaluate and improve our Services, products, marketing, and
                  your experience.
                </strong>{" "}
                We may process your information when we believe it is necessary
                to identify usage trends, determine the effectiveness of our
                promotional campaigns, and to evaluate and improve our Services,
                products, marketing, and your experience.
              </li>
              <li>
                <strong>To manage promotional activities.</strong> We may
                process your information to determine your eligibility for our
                promotional offers, and to provide promotional activities.
              </li>
              <li>
                <strong>
                  To comply with our legal obligations and manage legal matters.
                </strong>{" "}
                We may process your information to comply with our legal
                obligations, respond to legal requests, exercise, establish, or
                defend our legal rights, as well as to contact you about matters
                related to your use of our Services.
              </li>
            </ul>
          </section>

          <section>
            <h1 id="shareInfo">
              3. When And With Whom Do We Share Your Personal Information?
            </h1>

            <p>
              <strong>In short:</strong> We may share information in specific
              situations described in this section and/or with the following
              third parties.
            </p>
            <p>
              <strong>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </strong>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents (“<strong>third parties</strong>
              ”) who perform services for us or on our behalf and require access
              to such information to do that work. We have contracts in place
              with our third parties, which are designed to help safeguard your
              personal information. This means that they cannot do anything with
              your personal information unless we have instructed them to do it.
              They will also not share your personal information with any
              organization apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct. The third parties we may share personal information with
              are as follows:
            </p>
            <ul>
              <li>
                <strong>Amazon Web Services (AWS):</strong> Used for cloud
                computing services, to communicate and chat with users, data
                backup and security, functionality and infrastructure
                optimization, website hosting, and website performance
                monitoring.
              </li>
            </ul>
            <p>
              We also may need to share your personal information in the
              following situations:
            </p>
            <ul>
              <li>
                <strong>Business Transfers:</strong> We may share or transfer
                your information in connection with, or during any negotiations
                of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another
                company.
              </li>
              <li>
                <strong>If Required by Law:</strong> We may share your
                information with government agencies as required by law in
                response to lawful requests by public authorities, including to
                meet national security or law enforcement requirements. We also
                may share personal information to ensure the safety and security
                of individuals or assist in the prevention or investigation of
                criminal activity, and to establish, enforce, or defend against
                legal claims.
              </li>
            </ul>
          </section>

          <section>
            <h1>4. How Long Do We Keep Your Information?</h1>

            <p>
              <strong>In Short:</strong> We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy policy
              unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). Generally, no
              purpose in this policy will require us keeping your personal
              information for longer than it takes to fully notify all users who
              registered their phone number on{" "}
              <Link to="/">https://www.firstpeel.com</Link> of the availability
              of our mobile app. However, for individuals participating in our
              current promotional offer, we will retain personal information for
              a duration that extends until the conclusion of the activation
              period of the promotion. Personal information collected
              automatically–such as technical data, browser details, and network
              info–may be retained for a longer period when necessary for
              legitimate business purposes described in this privacy policy
              (e.g., security, operations).
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>

          <section>
            <h1 id="safeInfo">5. How Do We Keep Your Information Safe?</h1>

            <p>
              <strong>In short:</strong> We aim to protect your personal
              information through a system of organizational and technical
              security measures.
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>

          <section>
            <h1>6. Do We Collect Information From Minors?</h1>

            <p>
              <strong>In short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 years of age. If we learn that personal information
              from users less than 18 years of age has been collected, we will
              take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected
              from children under age 18, please contact us at
              information@firstpeel.com.
            </p>
          </section>

          <section>
            <h1 id="privacyRights">7. What Are Your Privacy Rights?</h1>

            <p>
              <strong>In short:</strong> You may review, change or terminate
              your account at any time.
            </p>
            <p>
              <strong>Withdrawing your consent:</strong> If we are relying on
              your consent to process your personal information, which may be
              express and/or implied consent depending on the applicable law,
              you have the right to withdraw your consent at any time. To
              withdraw your consent, please contact us at
              information@firstpeel.com with your request. We aim to process all
              consent withdrawal requests within 5 business days.
            </p>
            <p>
              Upon your request, we will immediately initiate the process to
              request the deletion of your personal data from third-party
              service providers. Please be aware that while we will take
              immediate action on our end, the complete removal of your data
              from third-party services, such as AWS, may not be immediate and
              might be subject to certain limitations like legal obligations or
              the completion of services already in progress. However,
              withdrawing your consent may result in certain functionalities of
              our Services becoming unavailable to you. In addition, please note
              that this will not affect the lawfulness of the processing before
              its withdrawal nor, when applicable law allows, will it affect the
              processing of your personal information conducted in reliance on
              lawful processing grounds other than consent.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at information@firstpeel.com.
            </p>
          </section>

          <section>
            <h1>8. Controls For Do-Not-Track Features</h1>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy policy.
            </p>
          </section>

          <section>
            <h1>9. Do We Make Updates To This Policy?</h1>

            <p>
              <strong>In short:</strong> Yes, we will update this policy as
              necessary to reflect changes in our information practices or to
              stay compliant with relevant laws.
            </p>
            <p>
              This privacy policy may be updated periodically and without prior
              notice to you to reflect changes in our information practices or
              relevant laws. We will post a notice on{" "}
              <Link to="/">https://www.firstpeel.com</Link> to notify you of any
              substantive changes to the way we collect and use information. The
              updated version will be indicated by an updated “Effective” date.
            </p>
          </section>

          <section>
            <h1>10. How Can You Contact Us About This Policy?</h1>
            <p>
              If you have questions or comments about this policy, you may email
              us at information@firstpeel.com.
            </p>
          </section>

          <section>
            <h1>
              11. How Can You Review, Update, Or Delete The Data We Collect From
              You?
            </h1>
            <p>
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it. To
              request to review, update, or delete your personal information,
              contact us at information@firstpeel.com.
            </p>
          </section>
        </main>
      </div>
      <FooterItem />
    </div>
  );
}

export default NewPrivacyScreen;
