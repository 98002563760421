import "./TermsScreen.css";
import { Link } from "react-router-dom";
import { chevronBackCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Helmet } from "react-helmet";
import FooterItem from "../FooterItem";
import logo from "../assets/Logo.png";

function NewTermsScreen() {
  return (
    <div className="termsContainer">
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div className="termsHeaderContainer">
        <Link to="/" className="termsBackButtonContainer">
          <IonIcon icon={chevronBackCircle} className="termsBackButton" />
        </Link>
        <div className="termsLogoContainer">
          <img src={logo} alt="logo" className="termsLogoImage" />
          <p className="termsLogoText">Firstpeel</p>
        </div>
      </div>
      <div className="termsContent">
        <h1 className="center title">Terms and Conditions</h1>
        <main>
          <h3>Effective March 25, 2024 at 2:00 PM PST</h3>
          <section>
            <h1>Agreement To Our Legal Terms</h1>
            <p>
              Welcome! We are FIRSTPEEL INC. (“
              <strong>Company,</strong>” “<strong>we,</strong>” “
              <strong>us,</strong>” “<strong>our</strong>”). We operate the
              website <Link to="/">https://www.firstpeel.com</Link> (the “
              <strong>Site</strong>”), along with all features and
              functionalities available on our Site, under these legal terms
              (the “<strong>Legal Terms</strong>”). Our services (collectively,
              the "<strong>Services</strong>") include:
            </p>
            <ul>
              <li>Our Site.</li>
              <li>
                Any features available on our Site, such as the functionality
                where users can register their phone numbers to receive an SMS
                notification regarding the launch of our mobile dating app.
              </li>
              <li>
                Interactions with our toll-free number (+1 888-698-4880), which
                include services such as sending the SMS notification and
                managing opt-out requests.
              </li>
            </ul>
            <p>You can contact us by email at contact@firstpeel.com</p>
            <p>
              These Legal Terms constitute a legally binding agreement made
              between you personally (“
              <strong>you</strong>”), and FIRSTPEEL INC., concerning your access
              to and use of the Services. You agree that by accessing the
              Services, you have read, understood, and agreed to be bound by all
              of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
              TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
              AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>
            <p>
              While we strive to address common challenges in online dating,
              please note that we cannot make specific guarantees regarding the
              app's features or capabilities.
            </p>
            <p>
              Please note that specific terms related to text messaging are
              detailed at the bottom of the waitlist sign-up form on{" "}
              <Link to="/">https://www.firstpeel.com</Link>. We encourage you to
              carefully review these terms before submitting your information.
              These terms outline important information about the text message
              you will receive from us regarding the availability of the
              Firstpeel app.
            </p>
            <p>
              We reserve the right to modify these Legal Terms at any time,
              without notice. Any changes will become effective immediately upon
              being posted on this page, and the “Effective” date will be
              updated. We will put a notice on{" "}
              <Link to="/">https://www.firstpeel.com</Link> to notify you of any
              substantial changes to our Legal Terms. By continuing to use the
              Services after these modifications, you are agreeing to and
              acknowledging the updated Legal Terms. We recommend that you
              periodically review this page to stay informed about any changes.
            </p>
            <p>
              IF YOU ARE NOT AT LEAST EIGHTEEN (18) YEARS OF AGE AND/OR YOU DO
              NOT RESIDE IN THE UNITED STATES, YOU ARE NOT PERMITTED TO USE THE
              SERVICES.
            </p>
          </section>

          <section>
            <h1>Our Services</h1>
            <p>
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.
            </p>
          </section>

          <section>
            <h1>Promotion: Free Lifetime Access for Early Sign-Ups</h1>

            <p>
              Eligibility: This promotional offer is available exclusively to
              the first 1000 individuals who successfully register a unique
              phone number on our Site to be notified of the launch of our
              mobile dating app, Firstpeel. Eligibility will be determined based
              on the order of registration tied to each distinct phone number.
              To be eligible, participants must meet the age and residency
              criteria as outlined in our Terms, specifically being at least 18
              years of age and residing within the United States.
            </p>

            <p>
              Offer Details: Eligible participants will receive free access to
              all premium features of Firstpeel, including any premium
              subscriptions, for the lifetime of the app. "Lifetime of the app"
              refers to the period during which Firstpeel is available and
              operational.
            </p>
            <p>Terms of the Offer:</p>
            <ul>
              <li>
                Activation Period: Eligible participants must activate their
                free premium access by creating an account on the app using the
                phone number they registered on our Site. This account must be
                created within 30 days (including the 30th day) after the
                official launch date and time of the app. Failure to activate
                within this period will result in forfeiture of the offer.
              </li>
              <li>
                Non-Transferable: This offer is non-transferable and is limited
                to the account associated with the original registration on the
                waitlist, identified by the phone number provided. It cannot be
                exchanged or shared with other accounts.
              </li>
              <li>
                No Monetary Value: The offer has no monetary value and cannot be
                redeemed for cash or any monetary equivalent.
              </li>
              <li>
                App Continuity: The offer is contingent on the continued
                operation of Firstpeel. Should the app be discontinued, the
                offer will naturally conclude.
              </li>
              <li>
                Changes and Termination: We reserve the right to modify or
                terminate this promotional offer at any time, for any reason,
                and without notice, subject to applicable law.
              </li>
            </ul>
          </section>

          <section>
            <h1>Intellectual Property Rights</h1>
            <h3>Our Intellectual Property</h3>

            <p>
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              “Content”), as well as the trademarks, service marks, and logos
              contained therein (the “Marks”).
            </p>
            <p>
              Our Content and Marks are subject to common law trademark rights
              and protected by copyright laws (and various other intellectual
              property rights and unfair competition laws) and treaties in the
              United States and around the world.
            </p>
            <p>
              The Content and Marks are provided in or through the Services “AS
              IS” for your personal, non-commercial use.
            </p>

            <h3>Your Use Of The Services</h3>

            <p>
              Subject to your compliance with these Legal Terms, including the
              “Prohibited Activities” section below, we grant you a
              non-exclusive, non-transferable, revocable license to:
            </p>
            <ul>
              <li>Access the Services.</li>
              <li>
                Download or print a copy of any portion of the Content to which
                you have properly gained access.
              </li>
            </ul>
            <p>solely for your personal, non-commercial use.</p>
            <p>
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </p>
            <p>
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to: contact@firstpeel.com. If
              we ever grant you the permission to post, reproduce, or publicly
              display any part of our Services or Content, you must identify us
              as the owners or licensors of the Services, Content, or Marks and
              ensure that any copyright or proprietary notice appears or is
              visible on posting, reproducing, or displaying our Content.
            </p>
            <p>
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.
            </p>
            <p>
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </p>
          </section>

          <section>
            <h1>User Representations</h1>
            <p>
              <p>By using the Services, you represent and warrant that:</p>
              <ol>
                <li>
                  You have the legal capacity and you agree to comply with these
                  Legal Terms.
                </li>
                <li>You are at least eighteen (18) years of age.</li>
                <li>You reside in the United States of America.</li>
                <li>
                  You will not access the Services through automated or
                  non-human means, whether through a bot, script, or otherwise.
                </li>
                <li>
                  You will not use the Services for any illegal or unauthorized
                  purpose.
                </li>
                <li>
                  Your use of the Services will not violate any applicable law
                  or regulation.
                </li>
                <li>
                  Your use of the Services must be based solely on your own
                  identity, personal information, and credentials.
                </li>
              </ol>
              <p>
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Services (or any portion thereof). IF YOU ARE NOT AT
                LEAST EIGHTEEN (18) YEARS OF AGE AND/OR YOU DO NOT RESIDE IN THE
                UNITED STATES, YOU ARE NOT PERMITTED TO USE THE SERVICES.
              </p>
              <p>
                You are responsible for safeguarding the phone number you submit
                for use in connection with our Services. We encourage you to be
                cautious in protecting your phone number from unauthorized use.
                Should there be any unauthorized use of your phone number in
                relation to our Services, or if you suspect that it has been
                compromised, please contact us immediately. We will take
                appropriate measures to address and resolve such incidents,
                consistent with our Privacy Policy and in accordance with
                applicable consumer protection laws.
              </p>
            </p>
          </section>

          <section>
            <h1>Prohibited Activities</h1>

            <p>
              You may not access or use the Services for any purpose other than
              that for which we make the Services available. The Services may
              not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </p>
            <p>As a user of the Services, you agree not to:</p>
            <ul>
              <li>
                Systematically retrieve data or other content from the Services
                to create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.
              </li>
              <li>
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive information.
              </li>
              <li>
                Circumvent, disable, or otherwise interfere with
                security-related features of the Services, including features
                that enforce limitations on the use of the Services and/or the
                Content contained therein.
              </li>
              <li>
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Services.
              </li>
              <li>
                Use any information obtained from the Services in order to
                harass, abuse, or harm another person.
              </li>
              <li>
                Use the Services in a manner inconsistent with any applicable
                laws or regulations.
              </li>
              <li>
                Engage in unauthorized framing of or linking to the Services.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Services or modifies, impairs,
                disrupts, alters, or interferes with the use, features,
                functions, operating, or maintenance of the Services.
              </li>
              <li>
                Engage in any automated use of the system, such as using scripts
                to send information, or using any data mining, robots, or
                similar data gathering and extraction tools.
              </li>
              <li>
                Delete the copyright or other proprietary rights notice from any
                Content.
              </li>
              <li>Attempt to impersonate another user or person.</li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1 x 1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).
              </li>
              <li>
                Interfere with, disrupt, or create an undue burden on the
                Services or the networks or services connected to the Services.
              </li>
              <li>
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Services to you.
              </li>
              <li>
                Attempt to bypass any measures of the Services designed to
                prevent or restrict access to the Services, or any portion of
                the Services.
              </li>
              <li>
                Copy or adapt the Services’ software, including but not limited
                to Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li>
                Except as permitted by applicable law, decipher, decompile,
                disassemble, or reverse engineer any of the software comprising
                or in any way making up a part of the Services.
              </li>
              <li>
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Services, or use or launch an unauthorized script or other
                software.
              </li>
              <li>
                Make any unauthorized use of the Services, including collecting
                information of users by electronic or other means for the
                purpose of sending unsolicited messages, or submitting
                information by automated means or under false pretenses.
              </li>
              <li>
                Use the Services as part of any effort to compete with us or
                otherwise use the Services and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </li>
              <li>
                Engage in any other conduct that restricts or inhibits anyone's
                use or enjoyment of the Services, or which, as determined by us,
                may harm the Company or users of the Services or expose them to
                liability.
              </li>
            </ul>
          </section>

          <section>
            <h1>User Suggestions and Feedback</h1>
            <p>
              We welcome and encourage our users to provide feedback, comments,
              and suggestions for improvements to our Services ("
              <strong>Feedback</strong>"). You acknowledge and agree that all
              Feedback you give us, whether through our Services, social media
              platforms, or other channels, will be considered non-confidential
              and non-proprietary. By providing us with any Feedback, you grant
              us a perpetual, worldwide, royalty-free, irrevocable,
              sublicensable, and transferable license to use, copy, modify,
              distribute, and implement such Feedback in our Services or in any
              other current or future services or products, without any payment
              or compensation to you. You represent and warrant that such
              Feedback is not subject to any intellectual property or
              proprietary rights of third parties, or, in the case where it is,
              you have all necessary rights and permissions to provide such
              Feedback and grant us the rights to use it as described above.
            </p>
          </section>

          <section>
            <h1>No Guarantees</h1>
            <p>
              Please be aware that using our Services does not guarantee access
              to the Firstpeel app, nor does it guarantee any specific features,
              functionalities, or availability of the app. The Services are
              intended to notify interested users about the app's release and
              does not imply any commitments regarding app access or features.
            </p>
          </section>

          <section>
            <h1>User Information</h1>
            <p>
              You and Services agree that we may access, store, process, and use
              any information and personal data that you provide following the
              terms of the Privacy Policy and your choices (including settings).
            </p>
          </section>

          <section>
            <h1>Services Management</h1>

            <p>We reserve the right, but not the obligation to:</p>
            <ol>
              <li>
                Take appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Legal Terms, including
                without limitation, reporting such users to law enforcement
                authorities.
              </li>
              <li>
                Manage the Services in a manner designed to protect our rights
                and property and to facilitate the proper functioning of the
                Services.
              </li>
            </ol>
          </section>

          <section>
            <h1>Privacy Policy</h1>
            <p>
              We care about data privacy and security. Please review our Privacy
              Policy:{" "}
              <Link to="/newprivacy">https://www.firstpeel.com/privacy</Link> .
              By using the Services, you agree to be bound by our Privacy
              Policy, which is incorporated into these Legal Terms. Please be
              advised the Services are hosted in the United States, and you are
              not permitted to use the Services if you do not reside in the
              United States. However, if you access the Services from any other
              region of the world with laws or other requirements governing
              personal data collection, use, or disclosure that differ from
              applicable laws in the United States, then through your continued
              use of the Services, you are transferring your data to the United
              States, and you expressly consent to have your data transferred to
              and processed in the United States.
            </p>
          </section>

          <section>
            <h1>Term and Termination</h1>
            <p>
              These Legal Terms shall remain in full force and effect while you
              use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
              BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
              THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES AT ANY TIME,
              WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </p>
            <p>
              If we find that a user is misusing our Services, such as
              submitting information without authorization or in violation of
              our Legal Terms, we reserve the right to take necessary actions.
              These actions may include removing the submitted information from
              the Services and taking appropriate legal measures, including
              civil, criminal, and injunctive redress, to prevent further
              misuse.
            </p>

            <p>
              To opt out of future SMS communication from us at any time, you
              can text STOP to +1 888-698-4880 or contact our customer support
              team directly at contact@firstpeel.com. Note that certain SMS
              messages are automatically sent to you by mobile carriers.
            </p>
          </section>

          <section>
            <h1>Modifications and Interruptions</h1>

            <p>
              We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Services. We will not be liable to you or any
              third party for any modification, price change, suspension, or
              discontinuance of the Services.
            </p>
            <p>
              We cannot guarantee the Services will be available at all times.
              We may experience hardware, software, or other problems or need to
              perform maintenance related to the Services, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the
              Services at any time or for any reason without notice to you. You
              agree that we have no liability whatsoever for any loss, damage,
              or inconvenience caused by your inability to access or use the
              Services during any downtime or discontinuance of the Services.
              Nothing in these Legal Terms will be construed to obligate us to
              maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.
            </p>
          </section>

          <section>
            <h1>Governing Law</h1>
            <p>
              These Legal Terms and your use of the Services are governed by and
              construed in accordance with the laws of the State of Delaware
              applicable to agreements made and to be entirely performed within
              the State of Delaware, without regard to its conflict of law
              principles.
            </p>
          </section>

          <section>
            <h1>Dispute Resolution</h1>
            <h3>Informal Negotiations</h3>
            <p>
              To expedite resolution and control the cost of any dispute,
              controversy, or claim related to these Legal Terms (each a
              “Dispute” and collectively, the “Disputes”) brought by either you
              or us (individually, a “Party” and collectively, the “Parties”),
              the Parties agree to first attempt to negotiate any Dispute
              (except those Disputes expressly provided below) informally for at
              least thirty (30) days before initiating arbitration. Such
              informal negotiations commence upon written notice from one Party
              to the other Party.
            </p>
            <h3>Binding Arbitration</h3>

            <p>
              If the Parties are unable to resolve a Dispute through informal
              negotiations, the Dispute (except those Disputes expressly
              excluded below) will be finally and exclusively resolved by
              binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
              YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
              The arbitration shall be commenced and conducted under the
              Commercial Arbitration Rules of the American Arbitration
              Association (“AAA”) and, where appropriate, the AAA’s
              Supplementary Procedures for Consumer Related Disputes (“AAA
              Consumer Rules”), both of which are available at the{" "}
              <a href="https://www.adr.org">
                American Arbitration Association website
              </a>
              . Your arbitration fees and your share of arbitrator compensation
              shall be governed by the AAA Consumer Rules and, where
              appropriate, limited by the AAA Consumer Rules. If such costs are
              determined by the arbitrator to be excessive, we will pay all
              arbitration fees and expenses. The arbitration may be conducted in
              person, through the submission of documents, by phone, or online.
              The arbitrator will make a decision in writing, but need not
              provide a statement of reasons unless requested by either Party.
              The arbitrator must follow applicable law, and any award may be
              challenged if the arbitrator fails to do so. Except where
              otherwise required by the applicable AAA rules or applicable law,
              the arbitration will take place in Orange County, California.
              Except as otherwise provided herein, the Parties may litigate in
              court to compel arbitration, stay proceedings pending arbitration,
              or to confirm, modify, vacate, or enter judgment on the award
              entered by the arbitrator.
            </p>
            <p>
              If for any reason, a Dispute proceeds in court rather than
              arbitration, the Dispute shall be commenced or prosecuted in the
              state and federal courts located in New Castle County, Delaware,
              and the Parties hereby consent to, and waive all defenses of lack
              of personal jurisdiction, and forum non conveniens with respect to
              venue and jurisdiction in such state and federal courts.
              Application of the United Nations Convention on Contracts for the
              International Sale of Goods and the Uniform Computer Information
              Transaction Act (UCITA) are excluded from these Legal Terms.
            </p>

            <p>
              In no event shall any Dispute brought by either Party related in
              any way to the Services be commenced more than one (1) year after
              the cause of action arose. If this provision is found to be
              illegal or unenforceable, then neither Party will elect to
              arbitrate any Dispute falling within that portion of this
              provision found to be illegal or unenforceable and such Dispute
              shall be decided by a court of competent jurisdiction within the
              courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </p>

            <h3>Restrictions</h3>
            <p>
              The parties agree that any arbitration shall be limited to the
              Dispute between the Parties individually. To the full extent
              permitted by law, (a) no arbitration shall be joined with any
              other proceeding; (b) there is no right or authority for any
              Dispute to be arbitrated on a class-action basis or to utilize
              class action procedures; and (c) there is no right or authority
              for any Dispute to be brought in a purported representative
              capacity on behalf of the general public or any other persons.
            </p>
            <h3>Exceptions to Informal Negotiations and Arbitration</h3>
            <p>
              The Parties agree that the following Disputes are not subject to
              the above provisions concerning informal negotiations binding
              arbitration: (a) any Disputes seeking to enforce or protect, or
              concerning the validity of, any of the intellectual property
              rights of a Party; (b) any Dispute related to, or arising from,
              allegations of theft, piracy, invasion of privacy, or unauthorized
              use; and (c) any claim for injunctive relief. If this provision is
              found to be illegal or unenforceable, then neither Party will
              elect to arbitrate any Dispute falling within that portion of this
              provision found to be illegal or unenforceable and such Dispute
              shall be decided by a court of competent jurisdiction within the
              courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </p>
          </section>

          <section>
            <h1>Corrections</h1>
            <p>
              There may be information on the Services that contains
              typographical errors, inaccuracies, or omissions, including
              descriptions, pricing, availability, and various other
              information. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update the information
              on the Services at any time, without prior notice.
            </p>
          </section>

          <section>
            <h1>Disclaimer</h1>
            <p>
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
              THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
              RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
              UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
              ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
              THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
              FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
              LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
              THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
              MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
              MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
              GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY HYPERLINKED WEBSITE.
            </p>
          </section>

          <section>
            <h1>Limitations of Liability</h1>
            <p>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
              PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR
              TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND
              INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
              MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </p>
          </section>

          <section>
            <h1>Indemnification</h1>
            <p>
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of: (1) use of the Services; (2) breach of these Legal
              Terms; (3) any breach of your representations and warranties set
              forth in these Legal Terms; or (4) your violation of the rights of
              a third party, including but not limited to intellectual property
              rights. Notwithstanding the foregoing, we reserve the right, at
              your expense, to assume the exclusive defense and control of any
              matter for which you are required to indemnify us, and you agree
              to cooperate, at your expense, with our defense of such claims. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding subject to this indemnification by
              contacting you at the phone number you have provided to us upon
              becoming aware of it.
            </p>
          </section>

          <section>
            <h1>User Data</h1>
            <p>
              We will maintain certain data that you transmit to the Services as
              outlined in our Privacy Policy. Although we perform regular
              routine backups of data, you are solely responsible for all data
              that you transmit or that relates to any activity you have
              undertaken using the Services. You agree that we shall have no
              liability to you for any loss or corruption of any such data, and
              you hereby waive any right of action against us arising from any
              such loss or corruption of such data.
            </p>
          </section>

          <section>
            <h1>Electronic Communications, Transactions, and Signatures</h1>
            <p>
              Visiting the Services, sending us emails, completing online forms,
              and interacting with our toll-free number (+1 888-698-4880),
              including SMS communications, constitute electronic
              communications. You consent to receive electronic communications,
              and you agree that all agreements, notices, disclosures, and other
              communications we provide to you electronically, via email and on
              the Services, satisfy any legal requirement that such
              communication be in writing. YOU HEREBY AGREE TO THE USE OF
              ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND
              TO ELECTRONIC DELIVERY OF NOTICES AND POLICIES. You hereby waive
              any rights or requirements under any statutes, regulations, rules,
              ordinances, or other laws in any jurisdiction which require an
              original signature or delivery or retention of non-electronic
              records.
            </p>
          </section>

          <section>
            <h1>California Users and Residents</h1>
            <p>
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </p>
          </section>

          <section>
            <h1>Miscellaneous</h1>
            <p>
              These Legal Terms and any policies or operating rules posted by us
              on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision. These
              Legal Terms operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these legal Terms is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Legal Terms or use of the
              Services. You agree that these Legal Terms will not be construed
              against us by virtue of having drafted them. Your hereby waive any
              and all defenses you may have based on the electronic form of
              these Legal Terms and the lack of signing by the parties hereto to
              execute these Legal Terms.
            </p>
          </section>

          <section>
            <h1>Contact Us</h1>
            <p>
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at contact@firstpeel.com.
            </p>
          </section>
        </main>
      </div>
      <FooterItem />
    </div>
  );
}

export default NewTermsScreen;
